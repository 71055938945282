import React, { useEffect, useState, useRef } from 'react';
import DaumPostcode from 'react-daum-postcode';
import styles from '../poo/PooRegisterDialog.module.css';
import PropTypes from 'prop-types';
import MiniMap from '../util/MiniMap';
import altImage from '../../resource/images/alt_image.png';
import CloseButton from '../../resource/images/svg/CloseButton';
import { modifyMachine, registerMachine } from '../../network/api/MachineAPI';
import { compressImage } from '../../utils/compressImage';

let imageFileBlob = null;

const MachineDialog = ({ selectData, isRegisterDialog, setIsDialogOpen }) => {
  const postPopupArea = useRef(null);
  const inputRef = useRef(null);
  const [category, setCategory] = useState(
    !isRegisterDialog ? selectData.category : 'tmach'
  );
  const [machineId, setMachineId] = useState(
    !isRegisterDialog ? selectData.id : null
  );
  const [machineName, setMachineName] = useState(
    !isRegisterDialog ? selectData.machineName : ''
  );
  const [machineSerialNumber, setMachineSerialNumber] = useState(
    !isRegisterDialog ? selectData.serialNumber : ''
  );
  const [vmReleaseStatus, setVmReleaseStatus] = useState(
    !isRegisterDialog ? selectData.vmReleaseStatus : true
  );
  const [cbReleaseStatus, setCbReleaseStatus] = useState(
    !isRegisterDialog ? selectData.cbReleaseStatus : true
  );

  const [vmStatus, setVmStatus] = useState(
    !isRegisterDialog ? selectData.vmStatus : 'active'
  );

  const [cbStatus, setCbStatus] = useState(
    !isRegisterDialog ? selectData.cbStatus : 'active'
  );
  const [image, setImage] = useState(
    !isRegisterDialog ? selectData.imageUrl : null
  );
  const [isPostPopupOpen, setIsPostPopupOpen] = useState(false);
  const [isNaverPopUpOpen, setIsNaverPopUpOpen] = useState(false);
  const [roadAddress, setRoadAddress] = useState(
    !isRegisterDialog ? selectData.address : ''
  );
  const [jibunAddress, setJibunAddress] = useState('');
  const [detailAddress, setDetailAddress] = useState(
    !isRegisterDialog ? selectData.detailAddress : ''
  );
  const [placePoint, setPlacePoint] = useState({
    latitude: !isRegisterDialog ? selectData.latitude : null,
    longitude: !isRegisterDialog ? selectData.longitude : null,
  });

  useEffect(() => {
    return () => {
      if (isRegisterDialog) {
        imageFileBlob = null;
      } else {
        imageFileBlob = selectData.imageUrl;
      }
    };
  }, []);

  useEffect(() => {
    function handleClickPopupOutside(e) {
      if (postPopupArea.current && !postPopupArea.current.contains(e.target)) {
        closePostCode();
      }
    }
    document.addEventListener('mousedown', handleClickPopupOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickPopupOutside);
    };
  }, [postPopupArea]);

  const openCamera = () => inputRef.current && inputRef.current.click();

  const detailAddressInputHandler = (e) => {
    setDetailAddress(e.target.value);
  };

  const machineNameInputHandler = (e) => {
    setMachineName(e.target.value);
  };

  const serialNumberInputHandler = (e) => {
    let inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      setMachineSerialNumber(e.target.value);
    }
  };

  const openPostCode = () => {
    setIsPostPopupOpen(true);
  };

  const closePostCode = () => {
    setIsPostPopupOpen(false);
  };

  const PostComplete = async (data) => {
    closePostCode();
    openNaverMapPopUp(data.address, data.jibunAddress);
  };

  const openNaverMapPopUp = (roadAddress, jibunAddress) => {
    if (roadAddress) {
      if (roadAddress !== '') {
        setRoadAddress(roadAddress);
        setJibunAddress(jibunAddress);
        setIsNaverPopUpOpen(true);
      } else {
        alert('우편번호 찾기를 진행해 주세요!');
      }
    }
  };

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 20 * 1024 * 1024) {
        alert('파일 크기는 20MB 이하여야 합니다.');
      } else {
        const compressedImage = await compressImage(file);
        const fileURL = URL.createObjectURL(compressedImage);
        imageFileBlob = compressedImage;
        setImage(fileURL);
      }
    }
    event.target.value = '';
  };

  const deleteImage = () => {
    setImage(null);
    imageFileBlob = null;
  };

  const registerButtonClick = async () => {
    if (
      category !== '' &&
      placePoint.latitude !== null &&
      placePoint.longitude !== null &&
      roadAddress !== '' &&
      detailAddress !== '' &&
      machineName !== '' &&
      machineSerialNumber !== ''
    ) {
      const machineInfo = {
        id: machineId,
        machineName: machineName,
        serialNumber: machineSerialNumber,
        address: roadAddress,
        detailAddress: detailAddress,
        imageUrl: image,
        category: category,
        longitude: placePoint.longitude,
        latitude: placePoint.latitude,
        vmStatus: vmStatus,
        cbStatus: cbStatus,
        vmReleaseStatus: vmReleaseStatus,
        cbReleaseStatus: cbReleaseStatus,
      };
      const formData = new FormData();

      if (imageFileBlob !== null && typeof imageFileBlob !== 'string') {
        formData.append('multipartFile', imageFileBlob);
      }

      const machineInfoString = JSON.stringify(machineInfo);

      // formData에 추가
      formData.append(
        'machine',
        new Blob([machineInfoString], { type: 'application/json' })
      );

      if (isRegisterDialog) {
        const response = await registerMachine(formData);
        if (response === 'success') {
          alert('등록성공');
          window.location.reload(true);
        } else if (response === 'over') {
          alert('등록실패');
        } else {
          alert('등록실패');
        }
      } else {
        const response = await modifyMachine(formData);
        if (response === 'success') {
          alert('수정성공');
          window.location.reload(true);
        } else if (response === 'over') {
          alert('수정실패');
        } else {
          alert('수정실패');
        }
      }
    } else {
      alert('필수항목들을 입력해 주세요');
    }
  };

  return (
    <div className={styles.wrapper}>
      {isNaverPopUpOpen ? (
        <MiniMap
          roadAddress={roadAddress}
          setPlacePoint={setPlacePoint}
          setIsNaverPopUpOpen={setIsNaverPopUpOpen}
          setRoadAddress={setRoadAddress}
          setJibunAddress={setJibunAddress}
        />
      ) : null}
      {isPostPopupOpen ? (
        <div className={styles.postPopupContainer} style={{ overflow: 'auto' }}>
          <div
            className={styles.postInnerPopup}
            ref={postPopupArea}
            style={{ margin: 'auto' }}>
            <DaumPostcode
              className={styles.daumPostPopup}
              onComplete={PostComplete}
              autoClose={false}
            />
            <div
              className={styles.postPopupCloseButton}
              onClick={() => {
                closePostCode();
              }}>
              닫기
            </div>
          </div>
        </div>
      ) : null}
      <div className={styles.innerWrapper}>
        <div
          className={styles.dialogCloseButton}
          onClick={() => {
            setIsDialogOpen({ isOpen: false });
          }}>
          <CloseButton />
        </div>
        <div className={styles.dialogContainer}>
          <div className={styles.inputTitle}>카테고리</div>
          <div className={styles.genderRadioGroup}>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('tmach');
              }}>
              {category === 'tmach' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>일체형</div>
            </div>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('vmach');
              }}>
              {category === 'vmach' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>자판기</div>
            </div>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('cbox');
              }}>
              {category === 'cbox' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>수거함</div>
            </div>
          </div>
          <div className={styles.inputTitle}>위치등록 (필수)</div>
          {placePoint.latitude !== null && placePoint.longitude !== null ? (
            <div
              className={styles.closeButton2}
              onClick={() => {
                openPostCode();
              }}>
              위치 수정
            </div>
          ) : (
            <div
              className={styles.loginButton2}
              onClick={() => {
                openPostCode();
              }}>
              위치 등록
            </div>
          )}
          <div className={styles.inputTitle}>도로명 주소</div>
          <div className={styles.defaultInput}>
            {roadAddress === '' ? '-' : roadAddress}
          </div>
          <div className={styles.inputTitle}>상세 주소 (필수)</div>
          <input
            type='text'
            maxLength={50}
            className={styles.inputId}
            placeholder='상세 주소'
            onChange={detailAddressInputHandler}
            value={detailAddress}
          />
          <div className={styles.inputTitle}>기기명 (필수)</div>
          <input
            type='text'
            maxLength={50}
            className={styles.inputId}
            placeholder='기기명'
            onChange={machineNameInputHandler}
            value={machineName}
          />
          <div className={styles.inputTitle}>기기일련번호 (필수)</div>
          <input
            type='tel'
            className={styles.inputId}
            placeholder="'-'없이 입력"
            maxLength={12}
            onChange={serialNumberInputHandler}
            value={machineSerialNumber}
          />
          <div className={styles.inputTitle}>이미지</div>
          <div className={styles.imageContainer}>
            {image !== null && image !== '' ? (
              <>
                <div className={styles.imageBoxWrapper}>
                  <input
                    id={`input-store-image-upload`}
                    type='file'
                    ref={inputRef}
                    accept='.jpg, .jpeg, .png'
                    onChange={handleFileInputChange}
                    style={{
                      position: 'absolute',
                      left: '-1000px',
                      visibility: 'hidden',
                    }}
                  />
                  <img
                    className={styles.afterImageContainer}
                    onClick={openCamera}
                    src={image}
                  />
                  <div
                    className={styles.deleteButton}
                    onClick={() => {
                      deleteImage();
                    }}>
                    삭제
                  </div>
                </div>
              </>
            ) : (
              <>
                <input
                  id='input-store-image-upload'
                  type='file'
                  ref={inputRef}
                  accept='.jpg, .jpeg, .png'
                  onChange={handleFileInputChange}
                  style={{
                    position: 'absolute',
                    left: '-1000px',
                    visibility: 'hidden',
                  }}
                />
                <label
                  htmlFor='input-store-image-upload'
                  className={styles.imageUploadButton}>
                  <img className={styles.profileBox} src={altImage} />
                </label>
              </>
            )}
          </div>
          {category !== 'cbox' ? (
            <>
              <div className={styles.inputTitle}>자판기 노출여부</div>
              <div className={styles.genderRadioGroup}>
                <div
                  className={styles.radioBox}
                  onClick={() => {
                    setVmReleaseStatus(true);
                  }}>
                  {vmReleaseStatus ? (
                    <div className={styles.selectedRadio}>
                      <div className={styles.selectedRadioInner} />
                    </div>
                  ) : (
                    <div className={styles.unselectedRadio} />
                  )}
                  <div className={styles.radioText}>예</div>
                </div>
                <div
                  className={styles.radioBox}
                  onClick={() => {
                    setVmReleaseStatus(false);
                  }}>
                  {!vmReleaseStatus ? (
                    <div className={styles.selectedRadio}>
                      <div className={styles.selectedRadioInner} />
                    </div>
                  ) : (
                    <div className={styles.unselectedRadio} />
                  )}
                  <div className={styles.radioText}>아니요</div>
                </div>
              </div>
              <div className={styles.inputTitle}>자판기 상태</div>
              <div className={styles.genderRadioGroup}>
                <div
                  className={styles.radioBox}
                  onClick={() => {
                    setVmStatus('active');
                  }}>
                  {vmStatus === 'active' ? (
                    <div className={styles.selectedRadio}>
                      <div className={styles.selectedRadioInner} />
                    </div>
                  ) : (
                    <div className={styles.unselectedRadio} />
                  )}
                  <div className={styles.radioText}>사용가능</div>
                </div>
                <div
                  className={styles.radioBox}
                  onClick={() => {
                    setVmStatus('maintenance');
                  }}>
                  {vmStatus === 'maintenance' ? (
                    <div className={styles.selectedRadio}>
                      <div className={styles.selectedRadioInner} />
                    </div>
                  ) : (
                    <div className={styles.unselectedRadio} />
                  )}
                  <div className={styles.radioText}>점검중</div>
                </div>
                <div
                  className={styles.radioBox}
                  onClick={() => {
                    setVmStatus('disable');
                  }}>
                  {vmStatus === 'disable' ? (
                    <div className={styles.selectedRadio}>
                      <div className={styles.selectedRadioInner} />
                    </div>
                  ) : (
                    <div className={styles.unselectedRadio} />
                  )}
                  <div className={styles.radioText}>사용불가</div>
                </div>
              </div>
            </>
          ) : null}
          {category !== 'vmach' ? (
            <>
              <div className={styles.inputTitle}>수거함 노출여부</div>
              <div className={styles.genderRadioGroup}>
                <div
                  className={styles.radioBox}
                  onClick={() => {
                    setCbReleaseStatus(true);
                  }}>
                  {cbReleaseStatus ? (
                    <div className={styles.selectedRadio}>
                      <div className={styles.selectedRadioInner} />
                    </div>
                  ) : (
                    <div className={styles.unselectedRadio} />
                  )}
                  <div className={styles.radioText}>예</div>
                </div>
                <div
                  className={styles.radioBox}
                  onClick={() => {
                    setCbReleaseStatus(false);
                  }}>
                  {!cbReleaseStatus ? (
                    <div className={styles.selectedRadio}>
                      <div className={styles.selectedRadioInner} />
                    </div>
                  ) : (
                    <div className={styles.unselectedRadio} />
                  )}
                  <div className={styles.radioText}>아니요</div>
                </div>
              </div>
              <div className={styles.inputTitle}>수거함 상태</div>
              <div className={styles.genderRadioGroup}>
                <div
                  className={styles.radioBox}
                  onClick={() => {
                    setCbStatus('active');
                  }}>
                  {cbStatus === 'active' ? (
                    <div className={styles.selectedRadio}>
                      <div className={styles.selectedRadioInner} />
                    </div>
                  ) : (
                    <div className={styles.unselectedRadio} />
                  )}
                  <div className={styles.radioText}>사용가능</div>
                </div>
                <div
                  className={styles.radioBox}
                  onClick={() => {
                    setCbStatus('maintenance');
                  }}>
                  {cbStatus === 'maintenance' ? (
                    <div className={styles.selectedRadio}>
                      <div className={styles.selectedRadioInner} />
                    </div>
                  ) : (
                    <div className={styles.unselectedRadio} />
                  )}
                  <div className={styles.radioText}>점검중</div>
                </div>
                <div
                  className={styles.radioBox}
                  onClick={() => {
                    setCbStatus('disable');
                  }}>
                  {cbStatus === 'disable' ? (
                    <div className={styles.selectedRadio}>
                      <div className={styles.selectedRadioInner} />
                    </div>
                  ) : (
                    <div className={styles.unselectedRadio} />
                  )}
                  <div className={styles.radioText}>사용불가</div>
                </div>
              </div>
            </>
          ) : null}
          <div
            className={styles.loginButton}
            onClick={() => {
              registerButtonClick();
            }}>
            {isRegisterDialog ? '등록' : '수정'}
          </div>
        </div>
      </div>
    </div>
  );
};

MachineDialog.propTypes = {
  selectData: PropTypes.object,
  isRegisterDialog: PropTypes.bool,
  setIsDialogOpen: PropTypes.func,
};

export default MachineDialog;
