import React, { useEffect, useState, useRef } from 'react';
import DaumPostcode from 'react-daum-postcode';
import styles from '../poo/PooRegisterDialog.module.css';
import PropTypes from 'prop-types';
import altImage from '../../resource/images/alt_image.png';
import MiniMap from '../util/MiniMap';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import { registerPlace } from '../../network/api/PlaceAPI';
import TextareaAutosize from 'react-textarea-autosize';
import CloseButton from '../../resource/images/svg/CloseButton';
import { compressImage } from '../../utils/compressImage';
import InputImage from '../util/InputImage';

let imageFileBlobList = [];

const PlaceRegisterDialog = ({ setIsRegisterPlaceDialogOpen }) => {
  const inputRef = useRef(null);
  const inputTextRef = useRef(null);
  const postPopupArea = useRef(null);
  const [category, setCategory] = useState('호텔');
  const [imageList, setImageList] = useState([]);
  const [placeName, setPlaceName] = useState('');
  const [linkUrl, setLinkLink] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isAllTime, setIsAllTime] = useState(false);
  const [openDayList, setOpenDayList] = useState([
    { day: '월', isDayOff: false, startTime: '', finishTime: '' },
    { day: '화', isDayOff: false, startTime: '', finishTime: '' },
    { day: '수', isDayOff: false, startTime: '', finishTime: '' },
    { day: '목', isDayOff: false, startTime: '', finishTime: '' },
    { day: '금', isDayOff: false, startTime: '', finishTime: '' },
    { day: '토', isDayOff: false, startTime: '', finishTime: '' },
    { day: '일', isDayOff: false, startTime: '', finishTime: '' },
  ]);
  const [isPostPopupOpen, setIsPostPopupOpen] = useState(false);
  const [isNaverPopUpOpen, setIsNaverPopUpOpen] = useState(false);
  const [roadAddress, setRoadAddress] = useState('');
  const [jibunAddress, setJibunAddress] = useState('');
  const [placePoint, setPlacePoint] = useState({
    latitude: null,
    longitude: null,
  });
  const [inputNotice, setInputNotice] = useState('');
  const [outputNotice, setOutputNotice] = useState('');
  const [noticeTextCount, setNoticeTextCount] = useState(0);

  useEffect(() => {
    return () => {
      imageFileBlobList.length = 0;
    };
  }, []);

  useEffect(() => {
    function handleClickPopupOutside(e) {
      if (postPopupArea.current && !postPopupArea.current.contains(e.target)) {
        closePostCode();
      }
    }
    document.addEventListener('mousedown', handleClickPopupOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickPopupOutside);
    };
  }, [postPopupArea]);

  const placeNameInputHandler = (e) => {
    setPlaceName(e.target.value);
  };

  const handleFileInputChange = async (event, index) => {
    if (imageFileBlobList.length >= 20) {
      alert('이미지는 20개 까지만 등록이 가능합니다.');
    } else {
      const file = event.target.files[0];
      if (file) {
        if (file.size > 20 * 1024 * 1024) {
          alert('파일 크기는 20MB 이하여야 합니다.');
        } else {
          const compressedImage = await compressImage(file);
          const fileURL = URL.createObjectURL(compressedImage);

          if (index < imageFileBlobList.length) {
            // 기존 이미지를 새 이미지로 대체
            imageFileBlobList[index] = compressedImage;
            setImageList((prevList) => {
              const newList = [...prevList];
              newList[index] = fileURL;
              return newList;
            });
          } else {
            // 새로운 이미지를 추가
            imageFileBlobList.push(compressedImage);
            setImageList((prevList) => [...prevList, fileURL]);
          }
        }
      }
    }
    event.target.value = '';
  };

  const deleteImage = (index) => {
    const updatedImageList = imageList.filter(
      (_, listIndex) => listIndex !== index
    );
    imageFileBlobList = imageFileBlobList.filter(
      (item, fileIndex) => fileIndex !== index
    );
    setImageList(updatedImageList);
  };

  const phoneInputHandler = (e) => {
    let inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      setPhoneNumber(e.target.value);
    }
  };

  const linkUrlInputHandler = (e) => {
    setLinkLink(e.target.value);
  };

  const handleStartTimeChange = (date, index) => {
    const updatedList = [...openDayList];
    updatedList[index] = { ...updatedList[index], startTime: date };

    setOpenDayList(updatedList);
  };

  const handleFinishTimeChange = (date, index) => {
    const updatedList = [...openDayList];
    updatedList[index] = { ...updatedList[index], finishTime: date };

    setOpenDayList(updatedList);
  };

  const noticeInputHandler = (e) => {
    setNoticeTextCount(
      e.target.value.substring(0, 10000).replace(/<br\s*\/?>/gm, '\n').length
    );
    const newText = e.target.value;
    setInputNotice(newText.substring(0, 10000));

    const paragraphs = newText.split('\n').map((line) => `<p>${line}</p>`);
    const resultString = paragraphs.join('');
    const final = convertToBrString(resultString);
    setOutputNotice(final);
  };

  const convertToBrString = (inputString) => {
    const regex = /(<p><\/p>)+/g;

    const resultString = inputString.replace(regex, (match) => {
      const blockCount = match.length / 7;
      if (blockCount > 1) {
        const brTags = Array(blockCount - 1)
          .fill('<br>')
          .join('');
        return `<p>${brTags}</p>`;
      } else {
        return match;
      }
    });

    return resultString;
  };

  const openPostCode = () => {
    setIsPostPopupOpen(true);
  };

  const closePostCode = () => {
    setIsPostPopupOpen(false);
  };

  const PostComplete = async (data) => {
    closePostCode();
    openNaverMapPopUp(data.address, data.jibunAddress);
  };

  const openNaverMapPopUp = (roadAddress, jibunAddress) => {
    if (roadAddress) {
      if (roadAddress !== '') {
        setRoadAddress(roadAddress);
        setJibunAddress(jibunAddress);
        setIsNaverPopUpOpen(true);
      } else {
        alert('우편번호 찾기를 진행해 주세요!');
      }
    }
  };

  const changeMinHeight = (height) => {
    if (inputTextRef.current) {
      if (height < 55) {
        inputTextRef.current.style.minHeight = '55px';
      } else {
        inputTextRef.current.style.minHeight = `${height}px`;
      }
    }
  };

  const openCamera = () => inputRef.current && inputRef.current.click();

  const registerButtonClick = async () => {
    if (
      placeName !== '' &&
      category !== '' &&
      placePoint.latitude !== null &&
      placePoint.longitude !== null
    ) {
      let openHour = [];
      if (isAllTime) {
        openHour = [
          '00:00 ~ 00:00',
          '00:00 ~ 00:00',
          '00:00 ~ 00:00',
          '00:00 ~ 00:00',
          '00:00 ~ 00:00',
          '00:00 ~ 00:00',
          '00:00 ~ 00:00',
        ];
      } else {
        for (const openDay of openDayList) {
          if (openDay.isDayOff) {
            openHour.push('휴무');
          } else {
            if (openDay.startTime !== '' && openDay.finishTime !== '') {
              const startHours = openDay.startTime
                .getHours()
                .toString()
                .padStart(2, '0');
              const startMinutes = openDay.startTime
                .getMinutes()
                .toString()
                .padStart(2, '0');
              const finishHours = openDay.finishTime
                .getHours()
                .toString()
                .padStart(2, '0');
              const finishMinutes = openDay.finishTime
                .getMinutes()
                .toString()
                .padStart(2, '0');
              openHour.push(
                `${startHours}:${startMinutes} ~ ${finishHours}:${finishMinutes}`
              );
            } else {
              alert('필수항목들을 입력해 주세요2');
              return;
            }
          }
        }
      }

      const formData = new FormData();

      for (const fileBlob of imageFileBlobList) {
        if (typeof fileBlob !== 'string') {
          formData.append('multipartFiles', fileBlob);
        }
      }

      const uploaderString = JSON.stringify({
        category: category,
        name: placeName,
        latitude: placePoint.latitude,
        longitude: placePoint.longitude,
        roadAddress: roadAddress,
        jibunAddress: jibunAddress,
        openDay: ['월', '화', '수', '목', '금', '토', '일'],
        openHour: openHour,
        notice: outputNotice,
        phone: phoneNumber,
        homepageUrl: linkUrl,
      });

      formData.append(
        'placeRequest',
        new Blob([uploaderString], { type: 'application/json' })
      );
      const response = await registerPlace(formData);
      if (response === 'success') {
        window.location.reload(true);
      } else if (response === 'over') {
        alert('등록실패(이미지 20개 제한)');
      } else {
        alert('등록실패');
      }
    } else {
      alert('필수항목들을 입력해 주세요');
    }
  };

  const handleToggleDayOff = (day) => {
    setOpenDayList(
      openDayList.map((openDay) => {
        if (openDay.day === day) {
          return { ...openDay, isDayOff: !openDay.isDayOff };
        } else {
          return openDay;
        }
      })
    );
  };

  return (
    <div className={styles.wrapper}>
      {isNaverPopUpOpen ? (
        <MiniMap
          roadAddress={roadAddress}
          setPlacePoint={setPlacePoint}
          setIsNaverPopUpOpen={setIsNaverPopUpOpen}
          setRoadAddress={setRoadAddress}
          setJibunAddress={setJibunAddress}
        />
      ) : null}
      {isPostPopupOpen ? (
        <div className={styles.postPopupContainer} style={{ overflow: 'auto' }}>
          <div
            className={styles.postInnerPopup}
            ref={postPopupArea}
            style={{ margin: 'auto' }}>
            <DaumPostcode
              className={styles.daumPostPopup}
              onComplete={PostComplete}
              autoClose={false}
            />
            <div
              className={styles.postPopupCloseButton}
              onClick={() => {
                closePostCode();
              }}>
              닫기
            </div>
          </div>
        </div>
      ) : null}
      <div className={styles.innerWrapper}>
        <div
          className={styles.dialogCloseButton}
          onClick={() => {
            setIsRegisterPlaceDialogOpen(false);
          }}>
          <CloseButton />
        </div>
        <div className={styles.dialogContainer}>
          <div className={styles.inputTitle}>카테고리</div>
          <div className={styles.genderRadioGroup}>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('호텔');
              }}>
              {category === '호텔' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>호텔</div>
            </div>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('공원');
              }}>
              {category === '공원' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>공원</div>
            </div>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('놀이터');
              }}>
              {category === '놀이터' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>놀이터</div>
            </div>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('미용');
              }}>
              {category === '미용' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>미용</div>
            </div>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('병원');
              }}>
              {category === '병원' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>병원</div>
            </div>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('쇼핑');
              }}>
              {category === '쇼핑' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>쇼핑</div>
            </div>
          </div>
          <div className={styles.genderRadioGroup}>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('숙박');
              }}>
              {category === '숙박' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>숙박</div>
            </div>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('식당');
              }}>
              {category === '식당' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>식당</div>
            </div>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('카페');
              }}>
              {category === '카페' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>카페</div>
            </div>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('캠핑');
              }}>
              {category === '캠핑' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>캠핑</div>
            </div>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('기타');
              }}>
              {category === '기타' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>기타</div>
            </div>
          </div>
          <div className={styles.inputTitle}>장소이름 (필수, 최대 50자)</div>
          <input
            type='text'
            maxLength={50}
            className={styles.inputId}
            placeholder='장소이름'
            onChange={placeNameInputHandler}
            value={placeName}
          />
          <div className={styles.inputTitle}>이미지 (최대 20개)</div>
          <InputImage
            imageList={imageList}
            handleFileInputChange={handleFileInputChange}
            deleteImage={deleteImage}
            isOnce={false}
          />
          <div className={styles.inputTitle}>위치등록 (필수)</div>
          {placePoint.latitude !== null && placePoint.longitude !== null ? (
            <div
              className={styles.closeButton2}
              onClick={() => {
                openPostCode();
              }}>
              등록완료
            </div>
          ) : (
            <div
              className={styles.loginButton2}
              onClick={() => {
                openPostCode();
              }}>
              위치 등록
            </div>
          )}
          <div className={styles.inputTitle}>도로명 주소</div>
          <div className={styles.defaultInput}>
            {roadAddress === '' ? '-' : roadAddress}
          </div>
          <div className={styles.inputTitle}>지번 주소</div>
          <div className={styles.defaultInput}>
            {jibunAddress === '' ? '-' : jibunAddress}
          </div>
          <div className={styles.inputTitle}>링크</div>
          <input
            type='text'
            className={styles.inputId}
            placeholder='링크'
            onChange={linkUrlInputHandler}
            value={linkUrl}
          />
          <div className={styles.inputTitle}>연락처</div>
          <input
            type='tel'
            className={styles.inputId}
            placeholder="연락처('-'없이 입력)"
            maxLength={12}
            onChange={phoneInputHandler}
            value={phoneNumber}
          />
          <div className={styles.inputTitleDay}>영업시간 (필수)</div>
          <div
            className={styles.allTimeRadioBox}
            onClick={() => {
              setIsAllTime(!isAllTime);
            }}>
            {isAllTime ? (
              <div className={styles.selectedRadio}>
                <div className={styles.selectedRadioInner} />
              </div>
            ) : (
              <div className={styles.unselectedRadio} />
            )}
            <div className={styles.radioText}>연중무휴</div>
          </div>
          {isAllTime
            ? null
            : openDayList.map((openDay, index) => (
                <div className='row-box' key={openDay.day}>
                  <div className={styles.dayText}>{openDay.day}</div>
                  <div
                    className={styles.dateRadioBox}
                    onClick={() => {
                      handleToggleDayOff(openDay.day);
                    }}>
                    {openDay.isDayOff ? (
                      <div className={styles.selectedRadio}>
                        <div className={styles.selectedRadioInner} />
                      </div>
                    ) : (
                      <div className={styles.unselectedRadio} />
                    )}
                    <div className={styles.radioText}>휴무</div>
                  </div>
                  {openDay.isDayOff ? (
                    <>
                      <div className={styles.defaultBox}>-</div>
                      <div className={styles.defaultBox}>-</div>
                    </>
                  ) : (
                    <>
                      <DatePicker
                        className={styles.customInputBoxDate}
                        selected={openDay.startTime}
                        onChange={(date) => {
                          handleStartTimeChange(date, index);
                        }}
                        locale={ko}
                        showTimeSelect
                        showTimeSelectOnly
                        placeholderText='시작시간'
                        timeIntervals={15}
                        timeCaption='Time'
                        dateFormat='HH:mm'
                      />
                      <DatePicker
                        className={styles.customInputBoxDate}
                        selected={openDay.finishTime}
                        onChange={(date) => {
                          handleFinishTimeChange(date, index);
                        }}
                        locale={ko}
                        showTimeSelect
                        showTimeSelectOnly
                        placeholderText='종료시간'
                        timeIntervals={15}
                        timeCaption='Time'
                        dateFormat='HH:mm'
                      />
                    </>
                  )}
                </div>
              ))}
          <div className={styles.inputTitle}>안내문구 (최대 10,000자)</div>
          <TextareaAutosize
            maxLength={10000}
            ref={inputTextRef}
            cacheMeasurements
            onHeightChange={(height) => {
              changeMinHeight(height);
            }}
            className={styles.quillBox}
            value={inputNotice}
            placeholder={'안내문구를 입력하세요'}
            onChange={noticeInputHandler}
          />
          <div>{noticeTextCount}/10000</div>
          <div
            className={styles.loginButton}
            onClick={() => {
              registerButtonClick();
            }}>
            내주변 장소 등록
          </div>
        </div>
      </div>
    </div>
  );
};

PlaceRegisterDialog.propTypes = {
  setIsRegisterPlaceDialogOpen: PropTypes.func,
};

export default PlaceRegisterDialog;
