import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './EventBannerModifyDialog.module.css';
import altImage from '../../resource/images/alt_image.png';
import PropTypes from 'prop-types';
import { modifyEventBanner } from '../../network/api/EventBannerAPI';
import CloseButton from '../../resource/images/svg/CloseButton';
import { compressImage } from '../../utils/compressImage';

let imageFileBlob = null;
let imageFileBlobList = [];

const EventBannerModifyDialog = ({
  eventBannerId,
  setIsModifyDialogOpen,
  eventBanner,
}) => {
  const navigate = useNavigate();
  const inputRefs = useRef([]);
  const inputRef = useRef(null);
  const [tempEventBanner, setTempEventBanner] = useState(eventBanner);

  useEffect(() => {
    return () => {
      imageFileBlob = eventBanner.imageUrl;
      imageFileBlobList = eventBanner.contentImages.map(
        (item) => item.imageUrl
      );
    };
  }, []);

  const bannerLinkInputHandler = (e) => {
    setTempEventBanner((prevState) => ({
      ...prevState,
      linkUrl: e.target.value,
    }));
  };

  const handleFileInputChange = async (event, index) => {
    if (index === 20 || imageFileBlobList.length >= 21) {
      alert('이미지는 20개 까지만 등록이 가능합니다.');
    } else {
      const file = event.target.files[0];
      if (file) {
        if (file.size > 20 * 1024 * 1024) {
          alert('파일 크기는 20MB 이하여야 합니다.');
        } else {
          const compressedImage = await compressImage(file);
          const fileURL = URL.createObjectURL(compressedImage);

          setTempEventBanner((prev) => {
            // 기존 이미지 리스트를 복사
            const updatedImageList = [...prev.contentImages];

            // 해당 인덱스의 객체에서 imageUrl 필드만 업데이트 (linkUrl은 유지)
            if (index < updatedImageList.length) {
              imageFileBlobList[index] = compressedImage;

              updatedImageList[index] = {
                ...updatedImageList[index], // 기존 객체의 속성 유지
                imageUrl: fileURL, // imageUrl 속성만 업데이트
              };
            } else {
              imageFileBlobList.push(compressedImage);

              // 새로운 객체 추가, link는 기존의 값 또는 null로 설정
              updatedImageList.push({
                imageUrl: fileURL, // 새 image 값
                linkUrl: '', // 새로운 객체에만 필요한 경우 빈 문자열로 설정 가능
              });
            }

            return {
              ...prev,
              contentImages: updatedImageList, // 수정된 이미지 리스트로 업데이트
            };
          });
        }
      }
    }
    event.target.value = '';
  };

  const handleFileInputChange2 = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 20 * 1024 * 1024) {
        alert('파일 크기는 20MB 이하여야 합니다.');
      } else {
        const compressedImage = await compressImage(file);
        const fileURL = URL.createObjectURL(compressedImage);
        imageFileBlob = compressedImage;
        setTempEventBanner((prevState) => ({
          ...prevState,
          imageUrl: fileURL,
        }));
      }
    }
    event.target.value = '';
  };

  const openCameras = (index) =>
    inputRefs.current[index] && inputRefs.current[index].click();
  const openCamera = () => {
    inputRef.current && inputRef.current.click();
  };

  const deleteImage = (index) => {
    const updatedImageList = tempEventBanner.contentImages.filter(
      (_, listIndex) => listIndex !== index
    );

    imageFileBlobList = imageFileBlobList.filter(
      (item, fileIndex) => fileIndex !== index
    );

    setTempEventBanner((prev) => ({
      ...prev,
      contentImages: updatedImageList, // contentImages 업데이트
    }));
  };

  const contentLinkInputHandler = (e, index) => {
    const newLinkValue = e.target.value;

    setTempEventBanner((prev) => {
      // 기존 이미지 리스트를 복사
      const updatedImageList = [...prev.contentImages];

      // 해당 인덱스의 객체에서 link 필드만 업데이트 (image는 유지)
      updatedImageList[index] = {
        ...updatedImageList[index], // 기존 객체의 속성 유지
        linkUrl: newLinkValue, // link 속성만 업데이트
      };

      return {
        ...prev, // 기존 tempEventBanner의 나머지 속성 유지
        contentImages: updatedImageList, // 수정된 이미지 리스트로 업데이트
      };
    });
  };

  const modifyButtonClick = async () => {
    if (tempEventBanner.imageUrl === null) {
      alert('이미지를 등록해 주세요');
      return;
    }

    const formData = new FormData();
    const imageStringList = [];

    for (const [index, fileBlob] of imageFileBlobList.entries()) {
      if (typeof fileBlob !== 'string') {
        formData.append('multipartFiles', fileBlob);
      } else {
        imageStringList.push({ image: fileBlob, index: index });
      }
    }

    if (imageFileBlob !== null && typeof imageFileBlob !== 'string') {
      formData.append('multipartFile', imageFileBlob);
    }

    const contentLinkList = tempEventBanner.contentImages.map(
      (item) => item.linkUrl
    );

    const uploaderString = JSON.stringify({
      id: tempEventBanner.id,
      linkUrl: tempEventBanner.linkUrl,
      imageUrl: tempEventBanner.imageUrl,
      contentLinkList: contentLinkList,
      contentImageDTOList: tempEventBanner.contentImages,
      imageUrlList: imageStringList,
    });
    formData.append(
      'modifyBannerRequest',
      new Blob([uploaderString], { type: 'application/json' })
    );

    const response = await modifyEventBanner(formData);
    if (response.result === 'success') {
      alert('변경완료');
      window.location.reload(true);
    } else {
      alert('변경실패');
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div
          className={styles.dialogCloseButton}
          onClick={() => {
            setIsModifyDialogOpen(false);
          }}>
          <CloseButton />
        </div>
        <div className={styles.inputTitle}>배너 링크</div>
        <input
          type='text'
          maxLength={2000}
          className={styles.inputId}
          placeholder='배너 링크'
          onChange={bannerLinkInputHandler}
          value={tempEventBanner.linkUrl}
        />
        <div className={styles.inputTitle}>
          상세 이미지 등록 (이미지가 순서대로 이어 붙어서 표시됩니다.)
        </div>
        <div className={styles.imageContainer}>
          {tempEventBanner.contentImages !== null &&
            tempEventBanner.contentImages.length !== 0 &&
            tempEventBanner.contentImages.map((item, index) => (
              <div key={index} className={styles.rowBox}>
                <div className={styles.imageBoxWrapper}>
                  <input
                    id={`input-store-image-upload-${index}`}
                    type='file'
                    ref={(el) => (inputRefs.current[index] = el)}
                    accept='.jpg, .jpeg, .png'
                    onChange={(e) => {
                      handleFileInputChange(e, index);
                    }}
                    style={{
                      position: 'absolute',
                      left: '-1000px',
                      visibility: 'hidden',
                    }}
                  />
                  <img
                    className={styles.afterImageContainer}
                    onClick={() => openCameras(index)}
                    src={item.imageUrl}
                  />
                  <div
                    className={styles.deleteButton}
                    onClick={() => {
                      deleteImage(index);
                    }}>
                    삭제
                  </div>
                </div>
                <input
                  type='text'
                  maxLength={2000}
                  className={styles.inputId}
                  placeholder='배너 링크'
                  onChange={(e) => {
                    contentLinkInputHandler(e, index);
                  }}
                  value={item.linkUrl}
                />
              </div>
            ))}
          <>
            <input
              id='input-store-image-upload-new'
              type='file'
              ref={(el) =>
                (inputRefs.current[tempEventBanner.contentImages.length] = el)
              }
              accept='.jpg, .jpeg, .png'
              onChange={(e) => {
                handleFileInputChange(e, tempEventBanner.contentImages.length);
              }}
              style={{
                position: 'absolute',
                left: '-1000px',
                visibility: 'hidden',
              }}
            />
            <label
              htmlFor='input-store-image-upload-new'
              className={styles.imageUploadButton}>
              <img className={styles.profileBox} src={altImage} />
            </label>
          </>
        </div>
        <div className={styles.inputTitle}>배너 이미지</div>
        <div className={styles.imageContainer}>
          {eventBanner.imageUrl !== null && eventBanner.imageUrl !== '' ? (
            <>
              <input
                id='input-store-image-upload'
                type='file'
                ref={inputRef}
                accept='.jpg, .jpeg, .png'
                onChange={handleFileInputChange2}
                style={{
                  position: 'absolute',
                  left: '-1000px',
                  visibility: 'hidden',
                }}
              />
              <img
                className={styles.afterImageContainer}
                onClick={openCamera}
                src={tempEventBanner.imageUrl}
              />
            </>
          ) : (
            <>
              <input
                id='input-store-image-upload'
                type='file'
                ref={inputRef}
                accept='.jpg, .jpeg, .png'
                onChange={handleFileInputChange2}
                style={{
                  position: 'absolute',
                  left: '-1000px',
                  visibility: 'hidden',
                }}
              />
              <label
                htmlFor='input-store-image-upload'
                className={styles.imageUploadButton}>
                <img
                  className={styles.profileBox}
                  src={tempEventBanner.imageUrl}
                />
              </label>
            </>
          )}
        </div>
        <div
          className={styles.loginButton}
          onClick={() => {
            modifyButtonClick();
          }}>
          변경하기
        </div>
      </div>
    </div>
  );
};

EventBannerModifyDialog.propTypes = {
  eventBannerId: PropTypes.number,
  setIsModifyDialogOpen: PropTypes.func,
  eventBanner: PropTypes.object,
};

export default EventBannerModifyDialog;
