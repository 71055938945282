import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './UserDogDialog.module.css';
import PropTypes from 'prop-types';
import { permitDogStatus, rejectDogStatus } from '../../network/api/DogAPI';
import CloseButton from '../../resource/images/svg/CloseButton';
import { dateChanger } from '../../utils/converter';

const UserDogDialog = ({ dogList, setIsDogDialogOpen }) => {
  const permitButtonClick = async (status, id) => {
    if (status !== '승인') {
      const response = await permitDogStatus({ id: id });

      if (response === 'success') {
        window.location.reload(true);
      } else {
        alert('승인실패');
      }
    }
  };

  const rejectButtonClick = async (status, id) => {
    if (status !== '반려') {
      const response = await rejectDogStatus({ id: id });

      if (response === 'success') {
        window.location.reload(true);
      } else {
        alert('반려실패');
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div
          className={styles.dialogCloseButton}
          onClick={() => {
            setIsDogDialogOpen(false);
          }}>
          <CloseButton />
        </div>
        <div className={styles.dialogContainer}>
          <div className={styles.inputTopTitle}>반려동물 관리</div>
          <div className={styles.dogListContainer}>
            <div className={styles.dogListTitleBox}>
              <p>반려동물</p>
              <p>첫번쨰 등록 여부</p>
              <p>이름</p>
              <p>견종</p>
              <p>생일</p>
              <p>몸무게</p>
              <p>성별</p>
              <p>중성화 여부</p>
              <p>반려동물 등록번호</p>
              <p>등록 소유자</p>
              <p>승인상태</p>
              <p>등록일</p>
              <p>승인일</p>
              <p>반려일</p>
              <p className={styles.emptyTitle}></p>
            </div>
            {dogList.map((dog) => (
              <div className={styles.banner} key={dog.id}>
                <div>{dog.species === 'dog' ? '강아지' : dog.species}</div>
                <div>{dog.isMain ? 'O' : 'X'}</div>
                <div>{dog.name}</div>
                <div>{dog.breed}</div>
                <div>{dog.birthday}</div>
                <div>{dog.weight}kg</div>
                <div>{dog.gender === 'female' ? '여' : '남'}</div>
                <div>{dog.neutered ? 'O' : 'X'}</div>
                <div>
                  {dog.animalRegistrationNumber !== ''
                    ? dog.animalRegistrationNumber
                    : '미등록'}
                </div>
                <div>{dog.ownerName !== '' ? dog.ownerName : '미등록'}</div>
                <div
                  className={
                    dog.status === '승인'
                      ? styles.blueText
                      : dog.status === '반려'
                      ? styles.redText
                      : null
                  }>
                  {dog.status}
                </div>
                <div>{dateChanger(dog.registerDate)}</div>
                <div>{dateChanger(dog.permitDate)}</div>
                <div>{dateChanger(dog.rejectDate)}</div>
                <div
                  className={styles.permitButton}
                  onClick={() => {
                    permitButtonClick(dog.status, dog.id);
                  }}>
                  승인
                </div>
                <div
                  className={styles.rejectButton}
                  onClick={() => {
                    rejectButtonClick(dog.status, dog.id);
                  }}>
                  반려
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

UserDogDialog.propTypes = {
  dogList: PropTypes.array,
  setIsDogDialogOpen: PropTypes.func,
};

export default UserDogDialog;
