import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './EventBannerRegisterDialog.module.css';
import PropTypes from 'prop-types';
import { registerEventBannerLink } from '../../network/api/EventBannerAPI';
import altImage from '../../resource/images/alt_image.png';
import CloseButton from '../../resource/images/svg/CloseButton';
import { compressImage } from '../../utils/compressImage';

let imageFileBlob = null;
let imageFileBlobList = [];

const EventBannerRegisterDialog = ({ setIsRegisterDialogOpen, intPage }) => {
  const inputRefs = useRef([]);
  const inputRef = useRef(null);
  const [bannerLink, setBannerLink] = useState('');
  const [image, setImage] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [bannerPosition, setBannerPosition] = useState('상');

  useEffect(() => {
    return () => {
      imageFileBlob = null;
      imageFileBlobList.length = 0;
    };
  }, []);

  const bannerLinkInputHandler = (e) => {
    setBannerLink(e.target.value);
  };

  const handleFileInputChange2 = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 20 * 1024 * 1024) {
        alert('파일 크기는 20MB 이하여야 합니다.');
      } else {
        const compressedImage = await compressImage(file);
        const fileURL = URL.createObjectURL(compressedImage);
        imageFileBlob = compressedImage;
        setImage(fileURL);
      }
    }
    event.target.value = '';
  };

  const openCameras = (index) =>
    inputRefs.current[index] && inputRefs.current[index].click();
  const openCamera = () => {
    inputRef.current && inputRef.current.click();
  };

  const modifyButtonClick = async () => {
    if (image !== null && image !== '') {
      const formData = new FormData();
      formData.append('multipartFile', imageFileBlob);

      for (const fileBlob of imageFileBlobList) {
        if (typeof fileBlob !== 'string') {
          formData.append('multipartFiles', fileBlob);
        }
      }

      const contentLinkList = imageList.map((item) => item.link);

      const uploaderString = JSON.stringify({
        linkUrl: bannerLink,
        position: bannerPosition,
        contentLinkList: contentLinkList,
      });
      formData.append(
        'modifyBannerRequest',
        new Blob([uploaderString], { type: 'application/json' })
      );
      const response = await registerEventBannerLink(formData);
      if (response === 'success') {
        alert('등록성공');
        window.location.reload(true);
      } else {
        alert('등록실패');
      }
    } else {
      alert('이미지를 등록해 주세요');
    }
  };

  const handleFileInputChange = async (event, index) => {
    if (index === 20 || imageFileBlobList.length >= 21) {
      alert('이미지는 20개 까지만 등록이 가능합니다.');
    } else {
      const file = event.target.files[0];
      if (file) {
        if (file.size > 20 * 1024 * 1024) {
          alert('파일 크기는 20MB 이하여야 합니다.');
        } else {
          const compressedImage = await compressImage(file);
          const fileURL = URL.createObjectURL(compressedImage);

          setImageList((prevImageList) => {
            // 기존 이미지 리스트를 복사
            const updatedImageList = [...prevImageList];

            if (index < updatedImageList.length) {
              // 기존 이미지를 새 이미지로 대체
              imageFileBlobList[index] = compressedImage;

              // 해당 인덱스의 객체에서 image 필드만 업데이트 (link는 유지)
              updatedImageList[index] = {
                ...updatedImageList[index], // 기존 객체의 link 속성 유지
                image: fileURL, // image 속성만 업데이트
              };
            } else {
              // 새로운 이미지를 추가
              imageFileBlobList.push(compressedImage);

              // 새로운 객체 추가, link는 기존의 값 또는 null로 설정
              updatedImageList.push({
                image: fileURL, // 새 image 값
                link: '', // 새로운 객체에만 필요한 경우 빈 문자열로 설정 가능
              });
            }

            return updatedImageList;
          });
        }
      }
    }
    event.target.value = '';
  };

  const deleteImage = (index) => {
    const updatedImageList = imageList.filter(
      (_, listIndex) => listIndex !== index
    );

    imageFileBlobList = imageFileBlobList.filter(
      (item, fileIndex) => fileIndex !== index
    );

    setImageList(updatedImageList);
  };

  const contentLinkInputHandler = (e, index) => {
    const newLinkValue = e.target.value;

    setImageList((prevImageList) => {
      // 기존 이미지 리스트를 복사
      const updatedImageList = [...prevImageList];

      // 해당 인덱스의 객체에서 link 필드만 업데이트 (image는 유지)
      updatedImageList[index] = {
        ...updatedImageList[index], // 기존 객체의 속성 유지 (image 속성 등)
        link: newLinkValue, // link 속성만 업데이트
      };

      return updatedImageList;
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div
          className={styles.dialogCloseButton}
          onClick={() => {
            setIsRegisterDialogOpen(false);
          }}>
          <CloseButton />
        </div>
        <div className={styles.inputTitle}>배너 위치</div>
        <div className={styles.genderRadioGroup}>
          <div
            className={styles.radioBox}
            onClick={() => {
              setBannerPosition('상');
            }}>
            {bannerPosition === '상' ? (
              <div className={styles.selectedRadio}>
                <div className={styles.selectedRadioInner} />
              </div>
            ) : (
              <div className={styles.unselectedRadio} />
            )}
            <div className={styles.radioText}>홈 상단배너</div>
          </div>
          <div
            className={styles.radioBox}
            onClick={() => {
              setBannerPosition('중');
            }}>
            {bannerPosition === '중' ? (
              <div className={styles.selectedRadio}>
                <div className={styles.selectedRadioInner} />
              </div>
            ) : (
              <div className={styles.unselectedRadio} />
            )}
            <div className={styles.radioText}>홈 띠배너</div>
          </div>
          <div
            className={styles.radioBox}
            onClick={() => {
              setBannerPosition('하');
            }}>
            {bannerPosition === '하' ? (
              <div className={styles.selectedRadio}>
                <div className={styles.selectedRadioInner} />
              </div>
            ) : (
              <div className={styles.unselectedRadio} />
            )}
            <div className={styles.radioText}>반려로운 소식 배너</div>
          </div>
          <div
            className={styles.radioBox}
            onClick={() => {
              setBannerPosition('qr');
            }}>
            {bannerPosition === 'qr' ? (
              <div className={styles.selectedRadio}>
                <div className={styles.selectedRadioInner} />
              </div>
            ) : (
              <div className={styles.unselectedRadio} />
            )}
            <div className={styles.radioText}>QR 배너</div>
          </div>
          <div
            className={styles.radioBox}
            onClick={() => {
              setBannerPosition('내정보');
            }}>
            {bannerPosition === '내정보' ? (
              <div className={styles.selectedRadio}>
                <div className={styles.selectedRadioInner} />
              </div>
            ) : (
              <div className={styles.unselectedRadio} />
            )}
            <div className={styles.radioText}>내정보 배너</div>
          </div>
        </div>
        <div className={styles.inputTitle}>배너 링크</div>
        <input
          type='text'
          maxLength={2000}
          className={styles.inputId}
          placeholder='배너 링크'
          onChange={bannerLinkInputHandler}
          value={bannerLink}
        />
        {(bannerPosition === '상' ||
          bannerPosition === 'qr' ||
          bannerPosition === '내정보') && (
          <>
            <div className={styles.inputTitle}>
              상세 이미지 등록 (이미지가 순서대로 이어 붙어서 표시됩니다.)
            </div>
            <div className={styles.imageContainer}>
              {imageList !== null &&
                imageList.length !== 0 &&
                imageList.map((item, index) => (
                  <div key={index} className={styles.rowBox}>
                    <div className={styles.imageBoxWrapper}>
                      <input
                        id={`input-store-image-upload-${index}`}
                        type='file'
                        ref={(el) => (inputRefs.current[index] = el)}
                        accept='.jpg, .jpeg, .png'
                        onChange={(e) => {
                          handleFileInputChange(e, index);
                        }}
                        style={{
                          position: 'absolute',
                          left: '-1000px',
                          visibility: 'hidden',
                        }}
                      />
                      <img
                        className={styles.afterImageContainer}
                        onClick={() => openCameras(index)}
                        src={item.image}
                      />
                      <div
                        className={styles.deleteButton}
                        onClick={() => {
                          deleteImage(index);
                        }}>
                        삭제
                      </div>
                    </div>
                    <input
                      type='text'
                      maxLength={2000}
                      className={styles.inputId}
                      placeholder='배너 링크'
                      onChange={(e) => {
                        contentLinkInputHandler(e, index);
                      }}
                      value={item.link}
                    />
                  </div>
                ))}
              <>
                <input
                  id='input-store-image-upload-new'
                  type='file'
                  ref={(el) => (inputRefs.current[imageList.length] = el)}
                  accept='.jpg, .jpeg, .png'
                  onChange={(e) => {
                    handleFileInputChange(e, imageList.length);
                  }}
                  style={{
                    position: 'absolute',
                    left: '-1000px',
                    width: 'fitContent',
                    visibility: 'hidden',
                  }}
                />
                <label
                  htmlFor='input-store-image-upload-new'
                  className={styles.imageUploadButton}>
                  <img className={styles.profileBox} src={altImage} />
                </label>
              </>
            </div>
          </>
        )}
        <div className={styles.inputTitle}>배너 이미지</div>
        <div className={styles.imageContainer}>
          {image !== null && image !== '' ? (
            <>
              <input
                id='input-banner-image-upload'
                type='file'
                ref={inputRef}
                accept='.jpg, .jpeg, .png'
                onChange={handleFileInputChange2}
                style={{
                  position: 'absolute',
                  left: '-1000px',
                  visibility: 'hidden',
                }}
              />
              <img
                className={styles.afterImageContainer}
                onClick={openCamera}
                src={image}
              />
            </>
          ) : (
            <>
              <input
                id='input-banner-image-upload'
                type='file'
                ref={inputRef}
                accept='.jpg, .jpeg, .png'
                onChange={handleFileInputChange2}
                style={{
                  position: 'absolute',
                  left: '-1000px',
                  visibility: 'hidden',
                }}
              />
              <label
                htmlFor='input-banner-image-upload'
                className={styles.imageUploadButton}>
                <img className={styles.profileBox} src={altImage} />
              </label>
            </>
          )}
        </div>
        <div
          className={styles.loginButton}
          onClick={() => {
            modifyButtonClick();
          }}>
          등록하기
        </div>
      </div>
    </div>
  );
};

EventBannerRegisterDialog.propTypes = {
  setIsRegisterDialogOpen: PropTypes.func,
  intPage: PropTypes.number,
};

export default EventBannerRegisterDialog;
