import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './EventScheduleModifyDialog.module.css';
import DaumPostcode from 'react-daum-postcode';
import PropTypes from 'prop-types';
import altImage from '../../resource/images/alt_image.png';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import {
  modifyEventSchedule,
  modifyEventScheduleImage,
} from '../../network/api/EventScheduleAPI';
import FileInput from '../util/FileInput';
import TextareaAutosize from 'react-textarea-autosize';
import CloseButton from '../../resource/images/svg/CloseButton';
import { compressImage } from '../../utils/compressImage';
import InputImage from '../util/InputImage';

let imageFileBlobList = [];

const EventScheduleModifyDialog = ({
  setIsModifyDialogOpen,
  eventSchedule,
  category,
  intPage,
}) => {
  const navigate = useNavigate();
  const postPopupArea = useRef(null);
  const inputTextRef = useRef(null);
  const inputPriceTextRef = useRef(null);
  const [linkUrl, setLinkLink] = useState(eventSchedule.homepageUrl);
  const [imageList, setImageList] = useState(
    eventSchedule.imageUrlList === null ? [] : eventSchedule.imageUrlList
  );
  const [eventName, setEventName] = useState(eventSchedule.name);
  const [phoneNumber, setPhoneNumber] = useState(eventSchedule.phone);
  const [location, setLocation] = useState(eventSchedule.location);
  const [place, setPlace] = useState(eventSchedule.detailLocation);
  const [jibunAddress, setJibunAddress] = useState(eventSchedule.jibunAddress);
  const [roadAddress, setRoadAddress] = useState(eventSchedule.roadAddress);
  const [startTime, setStartTime] = useState(
    eventSchedule.startTime === '' || eventSchedule.startTime === null
      ? ''
      : new Date(eventSchedule.startTime)
  );
  const [finishTime, setFinishTime] = useState(
    eventSchedule.finishTime === '' || eventSchedule.finishTime === null
      ? ''
      : new Date(eventSchedule.finishTime)
  );
  const [isFree, setIsFree] = useState(
    eventSchedule.lowestPrice === '0' && eventSchedule.maximumPrice === '0'
  );
  const [minimumPrice, setMinimumPrice] = useState(eventSchedule.lowestPrice);
  const [maximumPrice, setMaximumPrice] = useState(eventSchedule.maximumPrice);
  const [priceInfo, setPriceInfo] = useState(eventSchedule.priceInformation);
  const [notice, setNotice] = useState(eventSchedule.notice);
  const [isPostPopupOpen, setIsPostPopupOpen] = useState(false);
  const handleInputChange = (value) => {
    let plainText = value.replace(/<\/p>\s*<p>/gi, '\n');
    plainText = plainText.replace(/<br\s*[/]?>/gi, '\n');
    plainText = plainText.replace(/<\/?p>/g, '');
    return plainText;
  };
  const [inputPriceNotice, setInputPriceNotice] = useState(
    handleInputChange(eventSchedule.priceInformation)
  );
  const [outputPriceNotice, setOutputPriceNotice] = useState(
    eventSchedule.priceInformation
  );
  const [noticePriceTextCount, setNoticePriceTextCount] = useState(
    inputPriceNotice.length
  );
  const [inputNotice, setInputNotice] = useState(
    handleInputChange(eventSchedule.notice)
  );
  const [outputNotice, setOutputNotice] = useState(eventSchedule.notice);
  const [noticeTextCount, setNoticeTextCount] = useState(inputNotice.length);

  const startYear = 1990;
  const endYear = new Date().getFullYear();

  useEffect(() => {
    if (
      eventSchedule.imageUrlList !== null &&
      eventSchedule.imageUrlList.length !== 0 &&
      eventSchedule.imageUrlList[0] !== ''
    ) {
      for (const urlString of eventSchedule.imageUrlList) {
        imageFileBlobList.push(urlString);
      }
    }
    return () => {
      imageFileBlobList.length = 0;
    };
  }, []);

  const placeInputHandler = (e) => {
    setPlace(e.target.value);
  };

  const eventNameInputHandler = (e) => {
    setEventName(e.target.value);
  };

  const phoneInputHandler = (e) => {
    let inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      setPhoneNumber(e.target.value);
    }
  };

  const handleStartTimeChange = (date) => {
    if (finishTime !== '') {
      if (date > startTime) {
        alert('선택한 날짜는 종료 날짜보다 이후 입니다.');
      } else {
        setStartTime(date);
      }
    } else {
      setStartTime(date);
    }
  };

  const handleFinishTimeChange = (date) => {
    if (startTime !== '') {
      if (date < startTime) {
        alert('선택한 날짜는 시작 날짜보다 앞섭니다.');
      } else {
        setFinishTime(date);
      }
    } else {
      setFinishTime(date);
    }
  };

  const minimumPriceInputHandler = (e) => {
    let inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      setMinimumPrice(e.target.value);
    }
  };

  const maximumPriceInputHandler = (e) => {
    let inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      setMaximumPrice(e.target.value);
    }
  };

  const priceInfoInputHandler = (e) => {
    setNoticePriceTextCount(
      e.target.value.substring(0, 10000).replace(/<br\s*\/?>/gm, '\n').length
    );
    const newText = e.target.value;
    setInputPriceNotice(newText.substring(0, 10000));

    const paragraphs = newText.split('\n').map((line) => `<p>${line}</p>`);
    const resultString = paragraphs.join('');
    const final = convertToBrString(resultString);
    setOutputPriceNotice(final);
  };

  const noticeInputHandler = (e) => {
    setNoticeTextCount(
      e.target.value.substring(0, 10000).replace(/<br\s*\/?>/gm, '\n').length
    );
    const newText = e.target.value;
    setInputNotice(newText.substring(0, 10000));

    const paragraphs = newText.split('\n').map((line) => `<p>${line}</p>`);
    const resultString = paragraphs.join('');
    const final = convertToBrString(resultString);
    setOutputNotice(final);
  };

  const convertToBrString = (inputString) => {
    const regex = /(<p><\/p>)+/g;

    const resultString = inputString.replace(regex, (match) => {
      const blockCount = match.length / 7;
      if (blockCount > 1) {
        const brTags = Array(blockCount - 1)
          .fill('<br>')
          .join('');
        return `<p>${brTags}</p>`;
      } else {
        return match;
      }
    });

    return resultString;
  };

  const linkUrlInputHandler = (e) => {
    setLinkLink(e.target.value);
  };

  const openPostCode = () => {
    setIsPostPopupOpen(true);
  };

  const closePostCode = () => {
    setIsPostPopupOpen(false);
  };

  const PostComplete = async (data) => {
    closePostCode();
    if (data.address) {
      const extractedString = data.address.split(' ').slice(0, 2).join(' ');
      setLocation(extractedString);
      setRoadAddress(data.address);
      setJibunAddress(data.jibunAddress);
    } else {
      alert('우편번호 찾기를 진행해 주세요!');
    }
  };

  const changeMinHeight = (height) => {
    if (inputTextRef.current) {
      if (height < 55) {
        inputTextRef.current.style.minHeight = '55px';
      } else {
        inputTextRef.current.style.minHeight = `${height}px`;
      }
    }
  };

  const changePriceMinHeight = (height) => {
    if (inputPriceTextRef.current) {
      if (height < 55) {
        inputPriceTextRef.current.style.minHeight = '55px';
      } else {
        inputPriceTextRef.current.style.minHeight = `${height}px`;
      }
    }
  };

  const handleFileInputChange = async (event, index) => {
    if (imageFileBlobList.length >= 20) {
      alert('이미지는 20개 까지만 등록이 가능합니다.');
    } else {
      const file = event.target.files[0];
      if (file) {
        if (file.size > 20 * 1024 * 1024) {
          alert('파일 크기는 20MB 이하여야 합니다.');
        } else {
          const compressedImage = await compressImage(file);
          const fileURL = URL.createObjectURL(compressedImage);

          if (index < imageFileBlobList.length) {
            // 기존 이미지를 새 이미지로 대체
            imageFileBlobList[index] = compressedImage;
            setImageList((prevList) => {
              const newList = [...prevList];
              newList[index] = fileURL;
              return newList;
            });
          } else {
            // 새로운 이미지를 추가
            imageFileBlobList.push(compressedImage);
            setImageList((prevList) => [...prevList, fileURL]);
          }
        }
      }
    }
    event.target.value = '';
  };

  const deleteImage = (index) => {
    const updatedImageList = imageList.filter(
      (_, listIndex) => listIndex !== index
    );
    imageFileBlobList = imageFileBlobList.filter(
      (item, fileIndex) => fileIndex !== index
    );
    setImageList(updatedImageList);
  };

  const timeChanger = (time) => {
    const year = time.getFullYear();
    const month = String(time.getMonth() + 1).padStart(2, '0');
    const day = String(time.getDate()).padStart(2, '0');

    const dateString = `${year}-${month}-${day}`;
    return dateString;
  };

  const registerButtonClick = async () => {
    let isArrayEqual = false;
    if (imageList !== null) {
      isArrayEqual =
        JSON.stringify(eventSchedule.imageUrlList) ===
        JSON.stringify(imageList);
    }

    if (
      isArrayEqual &&
      linkUrl === eventSchedule.homepageUrl &&
      eventName === eventSchedule.name &&
      phoneNumber === eventSchedule.phone &&
      location === eventSchedule.location &&
      place === eventSchedule.detailLocation &&
      jibunAddress === eventSchedule.jibunAddress &&
      roadAddress === eventSchedule.roadAddress &&
      (startTime === null ||
        startTime === '' ||
        startTime.getTime() === new Date(eventSchedule.startTime).getTime()) &&
      (finishTime === null ||
        finishTime === '' ||
        finishTime.getTime() ===
          new Date(eventSchedule.finishTime).getTime()) &&
      minimumPrice === eventSchedule.lowestPrice &&
      maximumPrice === eventSchedule.maximumPrice &&
      outputPriceNotice === eventSchedule.priceInformation &&
      outputNotice === eventSchedule.notice
    ) {
      alert('변경사항이 없습니다');
      return;
    }

    if (
      imageList === null ||
      imageList.length === 0 ||
      linkUrl === '' ||
      eventName === '' ||
      phoneNumber === '' ||
      location === '' ||
      place === '' ||
      startTime === null ||
      startTime === '' ||
      (jibunAddress === '' && roadAddress === '') ||
      minimumPrice === ''
    ) {
      alert('입력항목들을 모두 입력해 주세요');
    } else if (!isArrayEqual) {
      const formData = new FormData();

      const imageStringList = [];

      for (const [index, fileBlob] of imageFileBlobList.entries()) {
        if (typeof fileBlob !== 'string') {
          formData.append('multipartFiles', fileBlob);
        } else {
          imageStringList.push({ image: fileBlob, index: index });
        }
      }

      const uploaderString = JSON.stringify({
        id: eventSchedule.id,
        name: eventName,
        location: location,
        detailLocation: place,
        jibunAddress: jibunAddress,
        roadAddress: roadAddress,
        startTime: timeChanger(startTime),
        finishTime: finishTime !== '' ? timeChanger(finishTime) : null,
        notice: outputNotice,
        lowestPrice: minimumPrice,
        maximumPrice: maximumPrice,
        priceInformation: outputPriceNotice,
        phone: phoneNumber,
        homepageUrl: linkUrl,
        imageUrlList: imageStringList,
      });
      formData.append(
        'eventScheduleDTO',
        new Blob([uploaderString], { type: 'application/json' })
      );

      const response = await modifyEventScheduleImage(formData);
      if (response === 'success') {
        alert('수정완료');
        window.location.reload(true);
      } else {
        alert('수정실패');
      }
    } else if (isArrayEqual) {
      const response = await modifyEventSchedule({
        id: eventSchedule.id,
        name: eventName,
        location: location,
        detailLocation: place,
        jibunAddress: jibunAddress,
        roadAddress: roadAddress,
        startTime: timeChanger(startTime),
        finishTime: finishTime !== '' ? timeChanger(finishTime) : null,
        notice: outputNotice,
        lowestPrice: isFree ? '0' : minimumPrice,
        maximumPrice: isFree ? '0' : maximumPrice,
        priceInformation: outputPriceNotice,
        phone: phoneNumber,
        homepageUrl: linkUrl,
      });
      if (response === 'success') {
        alert('수정완료');
        window.location.reload(true);
      } else {
        alert('수정실패');
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      {isPostPopupOpen ? (
        <div className={styles.postPopupContainer} style={{ overflow: 'auto' }}>
          <div
            className={styles.postInnerPopup}
            ref={postPopupArea}
            style={{ margin: 'auto' }}>
            <DaumPostcode
              className={styles.daumPostPopup}
              onComplete={PostComplete}
              autoClose={false}
            />
            <div
              className={styles.postPopupCloseButton}
              onClick={() => {
                closePostCode();
              }}>
              닫기
            </div>
          </div>
        </div>
      ) : null}
      <div className={styles.innerWrapper}>
        <div
          className={styles.dialogCloseButton}
          onClick={() => {
            setIsModifyDialogOpen(false);
          }}>
          <CloseButton />
        </div>
        <div className={styles.dialogContainer}>
          <div className={styles.inputTopTitle}>행사이름 (최대 50자)</div>
          <input
            type='text'
            maxLength={50}
            className={styles.inputId}
            placeholder='행사이름'
            onChange={eventNameInputHandler}
            value={eventName}
          />
          <div className={styles.inputTitle}>연락처</div>
          <input
            type='tel'
            className={styles.inputId}
            placeholder="연락처('-'없이 입력)"
            maxLength={12}
            onChange={phoneInputHandler}
            value={phoneNumber}
          />
          <div className={styles.inputTitle}>링크</div>
          <input
            type='text'
            className={styles.inputId}
            placeholder='링크'
            onChange={linkUrlInputHandler}
            value={linkUrl}
          />
          <div className={styles.inputTitle}>장소 (최대 50자)</div>
          <input
            type='text'
            maxLength={50}
            className={styles.inputId}
            placeholder='장소'
            onChange={placeInputHandler}
            value={place}
          />
          <div
            className={styles.addressButton}
            onClick={() => {
              openPostCode();
            }}>
            주소 찾기
          </div>
          <div className={styles.inputTitle}>지역</div>
          <div className={styles.defaultInput}>
            {location === '' ? '-' : location}
          </div>
          <div className={styles.inputTitle}>도로명주소</div>
          <div className={styles.defaultInput}>
            {roadAddress === '' ? '-' : roadAddress}
          </div>
          <div className={styles.inputTitle}>지번주소</div>
          <div className={styles.defaultInput}>
            {jibunAddress === '' ? '-' : jibunAddress}
          </div>
          <div className={styles.inputTitle}>기간</div>
          <div className={styles.timeBox}>
            <div className={styles.timeText}>시작일</div>
            <DatePicker
              className={styles.customInputBoxDate}
              locale={ko}
              showYearDropdown
              showMonthDropdown
              showMonthAfterYear
              yearDropdownItemNumber={endYear - startYear + 1}
              minDate={new Date(startYear, 0, 1)}
              maxDate={new Date(endYear, 11, 31)}
              placeholderText='YYYYMMDD'
              selected={startTime}
              onChange={handleStartTimeChange}
              dateFormat='yyyyMMdd'
              dropdownMode='select'
            />
            <div className={styles.timeText2}>종료일</div>
            <DatePicker
              className={styles.customInputBoxDate}
              locale={ko}
              showYearDropdown
              showMonthDropdown
              showMonthAfterYear
              yearDropdownItemNumber={endYear - startYear + 1}
              minDate={new Date(startYear, 0, 1)}
              maxDate={new Date(endYear, 11, 31)}
              placeholderText='YYYYMMDD'
              selected={finishTime}
              onChange={handleFinishTimeChange}
              dateFormat='yyyyMMdd'
              dropdownMode='select'
            />
          </div>
          <div className={styles.inputTitle}>금액</div>
          <div className={styles.timeBox}>
            {isFree ? (
              <>
                <div className={styles.defaultBox}>-</div>
                <p>원</p>
                <p>~</p>
                <div className={styles.defaultBox}>-</div>
                <p>원</p>
              </>
            ) : (
              <>
                <input
                  type='tel'
                  className={styles.inputId}
                  placeholder='최저가'
                  maxLength={12}
                  onChange={minimumPriceInputHandler}
                  value={minimumPrice}
                />
                <p>원</p>
                <p>~</p>
                <input
                  type='tel'
                  className={styles.inputId}
                  placeholder='최고가'
                  maxLength={12}
                  onChange={maximumPriceInputHandler}
                  value={maximumPrice}
                />
                <p>원</p>
              </>
            )}
            <div
              className={styles.freeRadioBox}
              onClick={() => {
                setIsFree(!isFree);
              }}>
              {isFree ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>무료</div>
            </div>
          </div>
          <div className={styles.inputTitle}>금액공지</div>
          <TextareaAutosize
            maxLength={10000}
            ref={inputPriceTextRef}
            cacheMeasurements
            onHeightChange={(height) => {
              changePriceMinHeight(height);
            }}
            className={styles.quillBox}
            value={inputPriceNotice}
            placeholder={'금액공지를 입력하세요'}
            onChange={priceInfoInputHandler}
          />
          <div>{noticePriceTextCount}/10000</div>
          <div className={styles.inputTitle}>안내문구 (최대 10,000자)</div>
          <TextareaAutosize
            maxLength={10000}
            ref={inputTextRef}
            cacheMeasurements
            onHeightChange={(height) => {
              changeMinHeight(height);
            }}
            className={styles.quillBox}
            value={inputNotice}
            placeholder={'안내문구를 입력하세요'}
            onChange={noticeInputHandler}
          />
          <div>{noticeTextCount}/10000</div>
          <div className={styles.inputTitle}>이미지</div>
          <InputImage
            imageList={imageList}
            handleFileInputChange={handleFileInputChange}
            deleteImage={deleteImage}
            isOnce={false}
          />
          <div
            className={styles.loginButton}
            onClick={() => {
              registerButtonClick();
            }}>
            수정하기
          </div>
        </div>
      </div>
    </div>
  );
};

EventScheduleModifyDialog.propTypes = {
  setIsModifyDialogOpen: PropTypes.func,
  eventSchedule: PropTypes.object,
  category: PropTypes.string,
  intPage: PropTypes.number,
};

export default EventScheduleModifyDialog;
