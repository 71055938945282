import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DaumPostcode from 'react-daum-postcode';
import styles from './PooRegisterDialog.module.css';
import PropTypes from 'prop-types';
import altImage from '../../resource/images/alt_image.png';
import MiniMap from '../util/MiniMap';
import { modifyPlace } from '../../network/api/PlaceAPI';
import CloseButton from '../../resource/images/svg/CloseButton';
import { compressImage } from '../../utils/compressImage';

let imageFileBlob = null;

const PooModifyDialog = ({ poo, setIsModifyDialogOpen }) => {
  const inputRef = useRef(null);
  const postPopupArea = useRef(null);
  const [category, setCategory] = useState(poo.category);
  const [image, setImage] = useState(poo.imageUrlList[0]);
  const [placeName, setPlaceName] = useState(poo.name);
  const [isPostPopupOpen, setIsPostPopupOpen] = useState(false);
  const [isNaverPopUpOpen, setIsNaverPopUpOpen] = useState(false);
  const [roadAddress, setRoadAddress] = useState(null);
  const [placePoint, setPlacePoint] = useState({
    latitude: poo.latitude,
    longitude: poo.longitude,
  });

  useEffect(() => {
    return () => {
      imageFileBlob = null;
    };
  }, []);

  useEffect(() => {
    function handleClickPopupOutside(e) {
      if (postPopupArea.current && !postPopupArea.current.contains(e.target)) {
        closePostCode();
      }
    }
    document.addEventListener('mousedown', handleClickPopupOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickPopupOutside);
    };
  }, [postPopupArea]);

  const placeNameInputHandler = (e) => {
    setPlaceName(e.target.value);
  };

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 20 * 1024 * 1024) {
        alert('파일 크기는 20MB 이하여야 합니다.');
      } else {
        const compressedImage = await compressImage(file);
        const fileURL = URL.createObjectURL(compressedImage);
        imageFileBlob = compressedImage;
        setImage(fileURL);
      }
    }
    event.target.value = '';
  };

  const openPostCode = () => {
    setIsPostPopupOpen(true);
  };

  const closePostCode = () => {
    setIsPostPopupOpen(false);
  };

  const PostComplete = async (data) => {
    closePostCode();
    openNaverMapPopUp(data.address);
  };

  const openNaverMapPopUp = (address) => {
    if (address) {
      if (address !== '') {
        setRoadAddress(address);
        setIsNaverPopUpOpen(true);
      } else {
        alert('우편번호 찾기를 진행해 주세요!');
      }
    }
  };

  const openCamera = () => inputRef.current && inputRef.current.click();

  const modifyButtonClick = async () => {
    if (
      poo.category === category &&
      poo.name === placeName &&
      poo.latitude === placePoint.latitude &&
      poo.longitude === placePoint.longitude &&
      poo.imageUrlList[0] === image
    ) {
      return;
    }

    if (
      placeName !== '' &&
      category !== '' &&
      placePoint.latitude !== null &&
      placePoint.longitude !== null
    ) {
      const formData = new FormData();

      formData.append('multipartFiles', imageFileBlob);

      const uploaderString = JSON.stringify({
        id: poo.id,
        category: category,
        name: placeName,
        latitude: placePoint.latitude,
        longitude: placePoint.longitude,
      });
      formData.append(
        'place',
        new Blob([uploaderString], { type: 'application/json' })
      );
      const response = await modifyPlace(formData);
      if (response === 'success') {
        window.location.reload(true);
      } else if (response === 'over') {
        alert('등록실패(이미지 20개 제한)');
      } else {
        alert('등록실패');
      }
    } else {
      alert('필수항목들을 입력해 주세요');
    }
  };

  return (
    <div className={styles.wrapper}>
      {isNaverPopUpOpen ? (
        <MiniMap
          roadAddress={roadAddress}
          setPlacePoint={setPlacePoint}
          setIsNaverPopUpOpen={setIsNaverPopUpOpen}
          setRoadAddress={setRoadAddress}
        />
      ) : null}
      {isPostPopupOpen ? (
        <div className={styles.postPopupContainer} style={{ overflow: 'auto' }}>
          <div
            className={styles.postInnerPopup}
            ref={postPopupArea}
            style={{ margin: 'auto' }}>
            <DaumPostcode
              className={styles.daumPostPopup}
              onComplete={PostComplete} // 값을 선택할 경우 실행되는 이벤트
              autoClose={false} // 값을 선택할 경우 사용되는 DOM을 제거하여 자동 닫힘 설정
            />
            <div
              className={styles.postPopupCloseButton}
              onClick={() => {
                closePostCode();
              }}>
              닫기
            </div>
          </div>
        </div>
      ) : null}
      <div className={styles.innerWrapper}>
        <div
          className={styles.dialogCloseButton}
          onClick={() => {
            setIsModifyDialogOpen(false);
          }}>
          <CloseButton />
        </div>
        <div className={styles.dialogContainer}>
          <div className={styles.inputTopTitle}>카테고리</div>
          <div className={styles.genderRadioGroup}>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('화장실');
              }}>
              {category === '화장실' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>화장실</div>
            </div>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('휴지통');
              }}>
              {category === '휴지통' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>휴지통</div>
            </div>
          </div>
          <div className={styles.inputTitle}>장소이름 (최대 50자)</div>
          <input
            type='text'
            maxLength={50}
            className={styles.inputId}
            placeholder='장소이름'
            onChange={placeNameInputHandler}
            value={placeName}
          />
          <div className={styles.inputTitle}>이미지</div>
          <div className={styles.imageContainer}>
            {image !== null && image !== '' ? (
              <>
                <input
                  id='input-store-image-upload'
                  type='file'
                  ref={inputRef}
                  accept='.jpg, .jpeg, .png'
                  onChange={handleFileInputChange}
                  style={{
                    position: 'absolute',
                    left: '-1000px',
                    visibility: 'hidden',
                  }}
                />
                <img
                  className={styles.afterImageContainer}
                  onClick={openCamera}
                  src={image}
                />
              </>
            ) : (
              <>
                <input
                  id='input-store-image-upload'
                  type='file'
                  ref={inputRef}
                  accept='.jpg, .jpeg, .png'
                  onChange={handleFileInputChange}
                  style={{
                    position: 'absolute',
                    left: '-1000px',
                    visibility: 'hidden',
                  }}
                />
                <label
                  htmlFor='input-store-image-upload'
                  className={styles.imageUploadButton}>
                  <img className={styles.profileBox} src={altImage} />
                </label>
              </>
            )}
          </div>
          <div className={styles.inputTitle}>위치등록</div>
          {placePoint.latitude !== null && placePoint.longitude !== null ? (
            <div
              className={styles.closeButton2}
              onClick={() => {
                openPostCode();
              }}>
              등록완료
            </div>
          ) : (
            <div
              className={styles.loginButton2}
              onClick={() => {
                openPostCode();
              }}>
              위치 등록
            </div>
          )}

          <div
            className={styles.loginButton}
            onClick={() => {
              modifyButtonClick();
            }}>
            댕똥처리 장소 수정
          </div>
        </div>
      </div>
    </div>
  );
};

PooModifyDialog.propTypes = {
  poo: PropTypes.object,
  setIsModifyDialogOpen: PropTypes.func,
};

export default PooModifyDialog;
